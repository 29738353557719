import React from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";

import Panel from "components/common/Panel";
import Image from 'components/common/Image';
import BlogCarousel from 'components/cards/BlogCarousel';
import panelData from 'constants/sustainability/panel';
import { isLpgDemergerOn } from 'constants/global';

import 'styles/pages/sustainability.css';

// asset images
// https://www.gatsbyjs.com/docs/how-to/images-and-media/importing-assets-into-files/
import heroBanner from 'images/sustainability/hero-sapling.png';
import coNeutralProduct from 'images/carbon-offset/climateactive-certified-product.png';

function CarbonOffsetPage() {

	return (
		<>
            <SEO title="Our sustainability initiatives | Kleenheat"
				 titleBespoke="true" 
            	 description="Kleenheat is changing our business practices to reduce carbon emissions and positively impact the health of the planet."
        	/>
            <Header />
            <LayoutMain>

            	{/* hero banner */}
            	<LayoutPanel background="mer-bg--primary-cyan"                 
			                 padding="mer-p-lg"
			                 theme="mer-theme--cyan"
                             id="sustainability-hero-banner">
			        <div className="container">
			            <div className="row mer-panel justify-content-center align-items-center">

                            <div className="col-10 col-sm-6 mer-text--size-lg">
                                <div className="content">
                                    <h2 className="mer-typography--headline1">Sustainability at Kleenheat</h2>
                                    <p>Kleenheat is making changes to our business practices to reduce emissions and positively impact the health of the planet.</p>
                                    <p>We're taking action locally, for the benefit of our customers and the wider community.</p>
                                </div>
                            </div>
                            <div className="mer-panel__images col-7 col-sm-6">
                                <img src={heroBanner} 
                                     className="mer-panel__image"
                                     alt="Sapling" />
                            </div>
                            
			            </div>
					</div>
			    </LayoutPanel>

			    {/* 1 */}
		    	<LayoutPanel background="mer-bg--gs-grey-lighter"
			                 padding="mer-py-lg"
			                 id="basics">
			        <div className="container">
			            <div className="row mer-panel">

			              	<div className="col-10 col-sm-7 order-sm-2 offset-sm-1">
			              		<div className="content mer-text--size-lg">
				                	<h2 className="mer-typography--headline2">Focusing on local, <span className="mer-text--no-wrap">for long-term change.</span></h2>
                                    <p>Kleenheat believes in a healthy, safe and sustainable future for all, and it is committed to a target of net zero scope 1 and 2 emissions by 2050.</p>
									<p>Kleenheat is also offering our customers the chance to take action alongside us, and is exploring further opportunities for decarbonisation with our business partners.</p>
	          					</div>
			              	</div>

							<div className="mer-panel__images col-5 col-sm-3 order-sm-1">
								{isLpgDemergerOn ? (
									<Image className="mer-panel__image" src="/icons/flowmoji/flowmoji-wa.svg" alt="Flowmoji WA" />
								) : (
									<Image className="mer-panel__image" src="/icons/flowmoji/flowmoji-wa-nt.svg" alt="Flowmoji WA and NT" />
								)}
						    </div>
			            </div>
			        </div>
			    </LayoutPanel>

                {/* 2 */}
                <LayoutPanel theme="mer-theme--light mer-bg--ui-light"
	        				 padding="mer-pt-ju">
	                <div className="container">
	                    <div className="row mer-panel">
	                    	
	                        <div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg text-center">
	                            <div className="content">
	                                <h1 className="mer-color--primary-cyan">Sustainability priorities​</h1>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </LayoutPanel>

		    	{/* usp */}
		    	<LayoutPanel background="mer-bg--ui-light"
			                 padding="mer-pb-lg">
			        <div className="container">
			        	<Panel data={panelData} 
			                   id="panel-1"
						/>
                        <Panel data={panelData} 
			                   id="panel-2"
                               imgLocal={coNeutralProduct}
						/>
                        <Panel data={panelData} 
			                   id="panel-3"
						/>
			        </div>
		        </LayoutPanel>

				<LayoutPanel background="mer-bg--gs-grey-lighter"
			                 padding="mer-py-lg"
			                 id="basics">
			        <div className="container">
			            <div className="row mer-panel">

			              	<div className="col-10 col-sm-7">
			              		<div className="content mer-text--size-lg">
				                	<h2 className="mer-typography--headline2">Feel good about local offsets</h2>
                                    <p>To date, Kleenheat has purchased offsets from Carbon Capture Project 1, a reforestation project covering 5,700 hectares across the Wheatbelt and Mid West.</p>
									<p>Carbon Capture Project 1 establishes permanent plantings of mallee eucalypt trees on land that was previously cleared for cropping and grazing.</p>
									<p>The project is registered with the Australian Clean Energy Regulator, the government body responsible for administering legislation that will reduce carbon emissions and increase the use of clean energy.</p>
	          					</div>
			              	</div>

							<div className="mer-panel__images col-5 col-sm-3 offset-sm-1">
                                <Image className="mer-panel__image" src="/icons/flowmoji/flowmoji-nature.svg" alt="Flowmoji nature" />
						    </div>
			            </div>
			        </div>
			    </LayoutPanel>

				<BlogCarousel id="blog"
            			  	  title="Sustainability at Kleenheat"
            			  	  titleCol="col-10"
            			  	  apiQuery="?tag=sustainability&posts_per_page=5"
    			/>
		    </LayoutMain>
			<Footer />
		</>
	)
}

export default CarbonOffsetPage;